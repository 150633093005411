import axios from "axios";
import { envconf } from "../envConfig";

const UserApiConsumer = {
  async registerUser(userData: object) {
    try {
      const response = await axios.post(
        `${envconf.SERVER_URL}/store-users/register`,
        userData
      );
      return response.data;
    } catch (error) {
      console.error("Error registering user:", error);
      throw error;
    }
  },

  async getStores() {
    try {
      const response = await axios.get(
        `${envconf.SERVER_URL}/all/pizza-stores`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching stores:", error);
      throw error;
    }
  },

  async getStoreUsers() {
    try {
      const response = await axios.get(`${envconf.SERVER_URL}/store-users`);
      return response.data;
    } catch (error) {
      console.error("Error fetching store users:", error);
      throw error;
    }
  },

  async updateUser(
    id: string,
    updatedUserData: {
      cin?: string;
      phone?: string;
      email?: string;
      store?: string;
      name?: string;
      role?: string;
    }
  ) {
    try {
      const response = await axios.patch(
        `${envconf.SERVER_URL}/store-users/update/${id}`,
        updatedUserData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  },
};

export default UserApiConsumer;
