import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sideBar";
import "./index.scss";
import Store from "../../Assets/store.svg";
import Layer from "../Layer";

const UserDetails: React.FC = () => {
  const location = useLocation();
  const { user } = location?.state || {};
  const navigate = useNavigate();

  const userDetail = [
    { key: "Role", value: user?.role },
    { key: "CIN", value: user?.cin },
    { key: "Téléphone", value: user?.phone },
    { key: "Email", value: user?.email },
    { key: "Assigné à", value: user?.store?.name },
  ];

  const navigateToUpdate = () => {
    navigate("/UserDetailsUpdate", { state: { user } });
  };

  return (
    <Layer title={"user-details"}>
      <div className="user-details">
        <div className="user-details-header">
          <span data-testid="details-title">Détails</span>
          <button
            className="purple-button"
            onClick={() => {
              navigateToUpdate();
            }}
            data-testid="modify-button"
          >
            Modifier
          </button>
        </div>
        <div className="user-details-content" data-testid="details-content">
          {user && (
            <>
              <span className="name" data-testid="user-fullname">
                {user?.fullname}
              </span>
              <div className="details-body">
                <div className="details-table">
                  {userDetail &&
                    userDetail?.map((detail) => {
                      return (
                        <div className="detail-column" key={detail?.key}>
                          <span
                            className="detailKey"
                            data-testid={`key-${detail?.key}`}
                          >
                            {detail?.key}
                          </span>
                          <span
                            className="detailValue"
                            data-testid={`value-${detail?.key}`}
                          >
                            {detail?.key === "Assigné à" ? (
                              <div className="assignTo">
                                <div className="store" key={user?.store?.name}>
                                  <img
                                    className="icon"
                                    src={Store}
                                    alt="store-icon"
                                  />
                                  <div
                                    className="label"
                                    data-testid="store-name"
                                  >
                                    {user?.store?.name}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              detail?.value
                            )}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="QR-code" data-testid="qr-code">
                  {user?.qrCode && <img src={user.qrCode} alt="QR Code" />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layer>
  );
};

export default UserDetails;
