import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Roles from "./Screens/Roles";
import Users from "./Screens/Users";
import "./App.scss";
import UserDetails from "./Screens/UserDetails";
import UserDetailsUpdate from "./Screens/UserDetailsUpdate";

const App: React.FC = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Roles />} />
          <Route path="/users" element={<Users />} />
          <Route path="/UserDetails" element={<UserDetails />} />
          <Route path="/UserDetailsUpdate" element={<UserDetailsUpdate />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
