// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.scss */
.users-container {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  min-height: 100vh;
  max-height: 100vh;
}
.users-container .users-page {
  width: 100%;
}
.users-container .users-header {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  height: 8rem;
  max-height: 8rem;
  min-height: 8rem;
}
.users-container .users-header .Users-button {
  width: 123px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #7673c9;
  box-shadow: 0px 4px 26px 0px #ece5fb;
  border: none;
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 3%;
}
.users-container .users-header span {
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.users-container .user-list {
  width: 90dvw;
  border-spacing: 0 1rem; /* Adjust the spacing between rows as needed */
  padding: 0 1rem;
  width: 80vw;
  overflow: auto;
}

.user-list::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Screens/Users/index.scss"],"names":[],"mappings":"AAAA,eAAA;AACA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AACI;EACE,YAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,oCAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AACN;AACI;EACE,WAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACN;AAGE;EACE,YAAA;EACA,sBAAA,EAAA,8CAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;AADJ;;AAKA;EACE,aAAA;AAFF","sourcesContent":["/* index.scss */\n.users-container {\n  display: flex;\n  max-width: 100%;\n  overflow: hidden;\n  min-height: 100vh;\n  max-height: 100vh;\n\n  .users-page {\n    width: 100%;\n  }\n\n  .users-header {\n    display: flex;\n    align-items: center;\n    padding: 0 2rem;\n    height: 8rem;\n    max-height: 8rem;\n    min-height: 8rem;\n    .Users-button {\n      width: 123px;\n      height: 42px;\n      flex-shrink: 0;\n      border-radius: 4px;\n      background: #7673c9;\n      box-shadow: 0px 4px 26px 0px #ece5fb;\n      border: none;\n      color: #fff;\n      font-family: \"Montserrat\";\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: normal;\n      margin-left: 3%;\n    }\n    span {\n      color: #000;\n      font-family: \"Montserrat\";\n      font-size: 20px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: normal;\n    }\n  }\n\n  .user-list {\n    width: 90dvw;\n    border-spacing: 0 1rem; /* Adjust the spacing between rows as needed */\n    padding: 0 1rem;\n    width: 80vw;\n    overflow: auto;\n  }\n}\n\n.user-list::-webkit-scrollbar{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
