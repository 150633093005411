import { useLayoutEffect, useState } from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  
 export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
  
    useLayoutEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowDimensions;
  }

  
export const sideBarContent = [
    { title: "Roles", value: "", route: "/", icon: "homeIcon" },
    {
      title: "Users",
      value: "",
      route: "/users",
      icon: "alimentationIcon",
    },
  
  ];
  