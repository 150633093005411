import React, { useEffect, useState } from "react";
import "./index.scss";
import Sidebar from "../../components/sideBar";
import UserTable from "../../components/tables";
import PopupUsers from "../../components/PopupUsers";
import UserApiConsumer from "../../Services/users";
import Lottie from "react-lottie";
import empty from "../../Assets/empty.json";
import Layer from "../Layer";

const Users: React.FC = () => {
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleUserAdded = () => {
    fetchUsers(); // Fetch the updated user list
  };

  const fetchUsers = async () => {
    try {
      const usersFromApi = await UserApiConsumer.getStoreUsers();
      console.log(usersFromApi);
      setAllUsers(usersFromApi.content.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []); // Empty dependency array to fetch users once when the component mounts

  return (
    <Layer title={"users"}>
      <div className="users-page">
        <div className="users-header">
          <span data-testid="users-header-title">Users</span>
          <button
            className="Users-button"
            onClick={handleOpenPopup}
            data-testid="add-user-button"
          >
            Ajouter
          </button>
        </div>
        {allUsers.length > 0 ? (
          <div className="user-list" data-testid="user-list">
            {allUsers.map((user) => (
              <UserTable key={user.id} user={user} />
            ))}
          </div>
        ) : (
          <div className="empty-list">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: empty,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={500}
              width={500}
            />
          </div>
        )}
        <PopupUsers
          isOpen={isPopupOpen}
          onRequestClose={handleClosePopup}
          onUserAdded={handleUserAdded}
          data-testid="popup-users"
        />
      </div>
    </Layer>
  );
};

export default Users;
