import React, { useEffect, useState } from "react";
import "./index.scss";
import edit from "../../Assets/edit.svg";
import PopupUpdate from "../popupUpdate";
import roleService from "../../Services/Roles";
import loading from "../../Assets/loading.json";
import Lottie from "react-lottie";

interface CardProps {
  role: {
    Role: string;
    id: string;
    Apps: string[];
  };
  onEditFinish: () => void; // Callback type
}

const Card: React.FC<CardProps> = ({ role, onEditFinish }) => {
  const { Role, Apps } = role;
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [localRoleCount, setLocalRoleCount] = useState<number>(0);
  const [isLoading, setLoading] = useState(true); // State to track loading status

  const openEditPopup = () => {
    setEditPopupOpen(true);
  };

  useEffect(() => {
    const countSort = async () => {
      try {
        setLoading(true); // Start loading
        // Fetch role data and update the state
        const data = await roleService.getUsersCount();

        if (data && role.Role in data) {
          const roleCount = data[role.Role].count || 0;
          setLocalRoleCount(roleCount);
        } else {
          console.error("Invalid data structure or role not found:", data);
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching role data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    countSort();
  }, [role]);

  const handleEditFinish = () => {
    setEditPopupOpen(false);
    // Call the callback passed from Roles component
    onEditFinish();
  };

  // Find the longest tag
  const longestTag = Apps.reduce(
    (acc, tag) => (tag.length > acc.length ? tag : acc),
    ""
  );

  // Separate tags into two groups: longest tag and other tags
  const longestTagGroup = Apps.filter((tag) => tag === longestTag);
  const otherTags = Apps.filter((tag) => tag !== longestTag);

  // Check if two tags can fit in the same row
  const canFitTwoTags = otherTags.length >= 2;
  const predefinedColors = [
    "#CFFFFC",
    "#D6EBFF",
    "#E2FFF8",
    "#E5FFD9",
    "#EADFCB",
    "#ECECEC",
    "#FAEBFF",
    "#FFD4EF",
    "#FFDCDC",
    "#FFF0F0",
    "#FFF2D8",
    "#FFF3DB",
  ];

  return (
    <div className="card-x" data-testid="card">
      <div className="header">
        <span data-testid="role-text">{Role}</span>
        <img
          className="Users-image"
          src={edit}
          alt="edit"
          onClick={openEditPopup}
          data-testid="edit-button"
        />
      </div>
      <div className="content">
        {isLoading ? ( // Display loading animation while data is being fetched
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loading,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
          />
        ) : (
          <>
            <span data-testid="local-role-count">{localRoleCount}</span>
            <div className="tags">
              {longestTagGroup.map((tag, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor:
                      predefinedColors[index % predefinedColors.length],
                  }}
                  className={`tag long-tag`}
                  data-testid={`tag-${index}`}
                >
                  {tag}
                </div>
              ))}
              {otherTags.map((tag, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor:
                      predefinedColors[
                        (index + longestTagGroup.length) %
                          predefinedColors.length
                      ],
                  }}
                  className={`tag ${
                    tag === longestTag
                      ? "long-tag"
                      : canFitTwoTags
                      ? "two-tags"
                      : ""
                  }`}
                  data-testid={`tag-${index + longestTagGroup.length}`}
                >
                  {tag}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {isEditPopupOpen && (
        <PopupUpdate
          isOpen={isEditPopupOpen}
          onRequestClose={handleEditFinish}
          role={role}
        />
      )}
    </div>
  );
};

export default Card;
