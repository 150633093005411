// UserDetails.tsx
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sideBar";
import "./index.scss";
import UserApiConsumer from "../../Services/users";
import roleService from "../../Services/Roles";
import Layer from "../Layer";

interface UserDetail {
  role?: string;
  cin?: string;
  phone?: string;
  email?: string;
  store?: string;
  fullname: string;
}

interface StoreOption {
  value: string;
  label: string;
}

interface RoleOption {
  value: string;
  label: string;
}
const UserDetailsUpdate: React.FC = () => {
  const location = useLocation();
  const { user } = location.state || ({} as { user?: UserDetail });

  const [editedUser, setEditedUser] = useState<UserDetail>({
    fullname: user?.fullname || "",
    role: user?.role || "",
    cin: user?.cin || "",
    phone: user?.phone || "",
    email: user?.email || "",
    store: user?.store || "",
  });

  const [storeOptions, setStoreOptions] = useState<StoreOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (key: keyof UserDetail, value: string) => {
    setEditedUser((prevUser) => ({
      ...prevUser,
      [key]: value,
    }));
  };

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const stores = await UserApiConsumer.getStores();
        const storeOptions = stores.content.map((store: any) => ({
          value: store.store.name,
          label: store.store.name,
        }));
        setStoreOptions(storeOptions);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const roles = await roleService.getAllRoles();
        const roleOptions = roles.content.map((role: any) => ({
          value: role.Role,
          label: role.Role,
        }));
        setRoleOptions(roleOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchStores();
    fetchRoles();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      await UserApiConsumer.updateUser(user?.id, editedUser ?? {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating user:", error);
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 8,
      borderColor: "white",
      background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF",
      boxShadow: "0px 4px 12px 0px #00000018",
      width: "60vw",
      height: "54px",
      textAlign: "start",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#32a78b",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? "none" : "white",
      color: state.isSelected ? "black" : "black",
      ":hover": {
        background: "#32a78b",
        color: "black",
      },
      textAlign: "start",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      textAlign: "start",
    }),
  };

  return (
    <Layer title={"user-details-update"}>
      <div className="user-details-update">
        <div className="user-details-update-header">
          <span data-testid="details-update-title">Détails</span>
          <button
            className="Green-button"
            onClick={handleSave}
            disabled={loading}
            data-testid="save-button"
          >
            {loading ? "Enregistrement..." : "Sauvegarder"}
          </button>
        </div>
        <div
          className="user-details-update-content"
          data-testid="details-update-content"
        >
          {editedUser && (
            <>
              <div className="details-update-body">
                <div className="details-table-update">
                  {/* Always render the fields */}
                  {["role", "cin", "phone", "email", "store", "fullname"].map(
                    (key) => (
                      <div
                        className="detail-update-column"
                        key={key}
                        data-testid={`detail-update-column-${key}`}
                      >
                        <span
                          className="detail-update-Key"
                          data-testid={`detail-update-Key-${key}`}
                        >
                          {key}
                        </span>
                        <span
                          className="detail-update-Value"
                          data-testid={`detail-update-Value-${key}`}
                        >
                          {key === "store" ? (
                            <div className="assignTo-update">
                              <Select
                                styles={customStyles}
                                options={storeOptions}
                                placeholder="Stores"
                                value={storeOptions.find(
                                  (store) => store.value === editedUser?.store
                                )}
                                onChange={(selectedValue) => {
                                  if (selectedValue) {
                                    setEditedUser((prevUser) => ({
                                      ...prevUser,
                                      store: selectedValue.value,
                                    }));
                                  }
                                }}
                                isSearchable
                                data-testid={`select-store-${key}`}
                              />
                            </div>
                          ) : key === "role" ? (
                            <div className="assignTo-update">
                              <Select
                                styles={customStyles}
                                options={roleOptions}
                                placeholder="Roles"
                                value={roleOptions.find(
                                  (role) => role.value === editedUser?.role
                                )}
                                onChange={(selectedValue) => {
                                  if (selectedValue) {
                                    setEditedUser((prevUser) => ({
                                      ...prevUser,
                                      role: selectedValue.value,
                                    }));
                                  }
                                }}
                                isSearchable
                                data-testid={`select-role-${key}`}
                              />
                            </div>
                          ) : (
                            <input
                              className="inputs"
                              type="text"
                              value={editedUser[key as keyof UserDetail]}
                              onChange={(e) =>
                                handleInputChange(
                                  key as keyof UserDetail,
                                  e.target.value
                                )
                              }
                              data-testid={`input-${key}`}
                            />
                          )}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layer>
  );
};

export default UserDetailsUpdate;
