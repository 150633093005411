import React, { useState } from "react";
import "./index.scss";
import edit from "../../Assets/edit.svg";
import Qrcode from "../../Assets/Qrcode.svg";
import { useNavigate } from "react-router-dom";
import { envconf } from "../../envConfig";

interface UserTableProps {
  user: {
    id?: string;
    fullname: string;
    role: string;
    cin: string;
    phone: string;
    email: string;
    store: string;
  };
}

const UserTable: React.FC<UserTableProps> = ({ user }) => {
  const navigate = useNavigate();

  const navigateUserDetails = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate("/UserDetails", { state: { user } });
  };

  const navigateToEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate("/UserDetailsUpdate", { state: { user } });
  };

  const downloadQRCode = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      const response = await fetch(
        `${envconf.SERVER_URL}/store-users/getQRCodeData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: user.id }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch QR code data from the server");
      }

      const qrCodeData = await response.json();

      const byteString = atob(qrCodeData.content.qrCodeData.split(",")[1]);
      const mimeString = qrCodeData.content.qrCodeData
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "user_qr_code.png";
      link.click();
    } catch (error) {
      console.error("Error downloading QR code:", error);
    }
  };

  return (
    <div
      className="user-table"
      data-testid="user-table"
      onClick={(event) => navigateUserDetails(event)}
    >
      <div className="user-box">
        <span className="Name" data-testid="user-fullname">
          {user.fullname}
        </span>
        <span className="Role" data-testid="user-role">
          {user.role}
        </span>
        <span className="Cin" data-testid="user-cin">
          {user.cin}
        </span>
        <span className="Phone" data-testid="user-phone">
          {user.phone}
        </span>
        <span className="email" data-testid="user-email">
          {user.email}
        </span>

        <div className="buttons">
          <img
            className="Qrcode-image"
            src={Qrcode}
            alt="Qrcode"
            onClick={(event) => {
              downloadQRCode(event);
            }}
            data-testid="qrcode-button"
          />

          <img
            className="edit-image"
            src={edit}
            alt="edit"
            onClick={(event) => navigateToEdit(event)}
            data-testid="edit-button"
          />
        </div>
      </div>
    </div>
  );
};

export default UserTable;
