import React, { useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { sideBarContent } from "../../utils";
import usersManager from "../../Assets/usersManager.svg";

function Menu({ showMenu, setShowMenu, toggleMenu }: any) {
  const navigate = useNavigate();
  return (
    <div className={`menu-wrapper`}>
      <div className={`menu-container ${showMenu ? "open" : ""}`}>
        <div className="logo-container">
          <div className="close-container">
            <i
              className="bx bx-x"
              style={{ fontSize: 26 }}
              onClick={toggleMenu}
            ></i>
          </div>
          <div className="img-container">
            <img src={usersManager} alt="appIcon" />
          </div>
        </div>
        <ul>
          {sideBarContent?.map((side, index) => (
            <li key={index} onClick={() => navigate(side?.route)}>
              {side?.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Menu;
