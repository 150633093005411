import axios from "axios";
import { envconf } from "../envConfig";

const roleService = {
  postRole: async (roleData: any) => {
    try {
      const response = await axios.post(
        `${envconf.SERVER_URL}/roles`,
        roleData
      );
      return response.data;
    } catch (error) {
      console.error("Error posting role:", error);
      throw error;
    }
  },
  getAllRoles: async () => {
    try {
      const response = await axios.get(`${envconf.SERVER_URL}/roles`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all roles:", error);
      throw error;
    }
  },
  updateRole: async (updatedRoleData: any) => {
    try {
      const response = await axios.put(
        `${envconf.SERVER_URL}/roles/${updatedRoleData.id}`,
        updatedRoleData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating role:", error);
      throw error;
    }
  },
  getApps: async () => {
    try {
      const response = await axios.get(`${envconf.SERVER_URL}/apps`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all roles:", error);
      throw error;
    }
  },

  getUsersCount: async () => {
    try {
      const response = await axios.get(`${envconf.SERVER_URL}/user-counts`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all roles:", error);
      throw error;
    }
  },
};

export default roleService;
