// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-table {
  padding: 5px;
  max-width: 95%;
  width: 75dvw;
  text-align: center;
  min-width: 1200px;
  width: 1200px;
}
.user-table .user-box {
  display: flex;
  justify-content: space-around;
  border-left: 5px solid #7673c9;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  height: 50px;
  box-shadow: 1px 4px 4px 0px rgba(255, 241, 239, 0.1019607843);
}
.user-table .user-box .Name,
.user-table .user-box .Cin,
.user-table .user-box .Phone,
.user-table .user-box .email {
  flex: 1 1;
  max-width: 100px;
  white-space: nowrap;
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.user-table .user-box .Role {
  flex: 1 1;
  max-width: 100px;
  white-space: nowrap;
  color: #7673c9;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.user-table .user-box .buttons {
  width: 10%;
  display: flex;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,6BAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,6DAAA;AAEJ;AAAI;;;;EAIE,SAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAEN;AAAI;EACE,SAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAEN;AAAI;EACE,UAAA;EACA,aAAA;EACA,6BAAA;AAEN","sourcesContent":[".user-table {\n  padding: 5px;\n  max-width: 95%;\n  width: 75dvw;\n  text-align: center;\n  min-width: 1200px;\n  width: 1200px;\n  .user-box {\n    display: flex;\n    justify-content: space-around;\n    border-left: 5px solid #7673c9;\n    align-items: center;\n    border-radius: 6px;\n    background: #fff;\n    height: 50px;\n    box-shadow: 1px 4px 4px 0px #fff1ef1a;\n\n    .Name,\n    .Cin,\n    .Phone,\n    .email {\n      flex: 1;\n      max-width: 100px;\n      white-space: nowrap;\n      color: #000;\n      font-family: \"Montserrat\";\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n      text-align: start;\n    }\n    .Role {\n      flex: 1;\n      max-width: 100px;\n      white-space: nowrap;\n      color: #7673c9;\n      font-family: \"Montserrat\";\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n      text-align: start;\n    }\n    .buttons {\n      width: 10%;\n      display: flex;\n      justify-content: space-around;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
