// Popup.tsx

import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./index.scss";
import GreenTick from "../../Assets/GreenTick.svg";
import roleService from "../../Services/Roles";

interface PopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const TIMEOUT_DURATION = 1000;

const fetchOptionsFromApi = async () => {
  try {
    // Fetch options from API using roleService or another appropriate service
    const optionsFromApi = await roleService.getApps();
    return optionsFromApi;
  } catch (error) {
    console.error("Error fetching options:", error);
    return [];
  }
};

const Popup: React.FC<PopupProps> = ({ isOpen, onRequestClose }) => {
  const [label, setLabel] = useState<string>("");
  const [roleAdded, setRoleAdded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [tags, setTags] = useState<{ value: string; label: string }[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const handleRemoveTag = (removedTag: { value: string; label: string }) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== removedTag));
    setOptions((prevOptions) => [...prevOptions, removedTag]);
  };

  const handleConfirm = async () => {
    try {
      const roleData = {
        Role: label,
        Apps: tags.map((tag) => tag.label),
      };

      const response = await roleService.postRole(roleData);

      if (response.success) {
        setRoleAdded(true);

        setLabel("");
        setTags([]);

        setTimeout(() => {
          setRoleAdded(false);
          onRequestClose();
          fetchOptionsFromApi().then((apiOptions) =>
            setOptions(
              apiOptions.map((option: any) => ({
                value: option.appName,
                label: option.appName,
              }))
            )
          );
        }, TIMEOUT_DURATION);
      } else {
        console.error("Error adding role:", response.message);
      }
    } catch (error) {
      console.error("Error adding role:", error);
    }
  };

  const handleCancel = () => {
    setRoleAdded(false);
    onRequestClose();
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 8,
      borderColor: "white",
      background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF",
      boxShadow: `0px 4px 12px 0px #00000018`,
      marginTop: "2%",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#7673C9",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? "none" : "white",
      color: state.isSelected ? "black" : "black",
      ":hover": {
        background: "#7673C9",
        color: "black",
      },
    }),
  };

  useEffect(() => {
    fetchOptionsFromApi().then((apiOptions) =>
      setOptions(
        apiOptions?.map((option: any) => ({
          value: option.appName,
          label: option.appName,
        }))
      )
    );
  }, []);

  return (
    <div className={`modal ${isOpen ? "open" : ""}`} data-testid="popup">
      <div
        className="modal-overlay"
        onClick={handleCancel}
        data-testid="overlay"
      ></div>
      <div className={`modal-content ${roleAdded ? "new-role" : ""}`}>
        <div className="modal-header">
          <span data-testid="modal-header-text">
            {roleAdded ? "" : "Nouveau Rôle"}
          </span>
        </div>
        <div className="modal-body" data-testid="modal-body">
          <div className="success-message" data-testid="success-message">
            {roleAdded ? (
              <div className="SuccessContainer">
                <img src={GreenTick} alt="Green Tick" />
                <span className="success-text">Rôle ajouté avec succès</span>
              </div>
            ) : (
              <>
                <input
                  className="title-input"
                  type="text"
                  id="labelInput"
                  value={label}
                  placeholder="Titre"
                  onChange={(e) => setLabel(e.target.value)}
                  data-testid="title-input"
                />
                <Select
                  styles={customStyles}
                  options={options}
                  placeholder="Sélectionnez une application"
                  value={selectedOption}
                  onChange={(selectedValue) => {
                    setSelectedOption(selectedValue);
                    if (selectedValue) {
                      setTags([...tags, selectedValue]);
                      setOptions(
                        options.filter((option) => option !== selectedValue)
                      );
                    }
                  }}
                  isClearable
                  isSearchable
                  data-testid="select-dropdown"
                />

                <div className="tags" data-testid="tag-container">
                  {tags.map((tag, index) => (
                    <div
                      key={tag.value}
                      className="tag"
                      data-testid={`tag-${index}`}
                    >
                      <span>{tag.label}</span>
                      <button onClick={() => handleRemoveTag(tag)}>
                        <i
                          className="bx bx-x"
                          style={{
                            color: "#7673C9",
                            fontSize: 14,
                          }}
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>

                <div className="buttons" data-testid="buttons-container">
                  <button onClick={handleCancel} data-testid="cancel-button">
                    Annuler
                  </button>
                  <button onClick={handleConfirm} data-testid="confirm-button">
                    Confirmer
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
