// Sidebar.jsx
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.scss";
import usersManager from "../../Assets/usersManager.svg";
import Users from "../../Assets/users.svg";
import Roles from "../../Assets/Roles.svg";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToRoles = () => {
    navigate("/");
  };

  const navigateToUsers = () => {
    navigate("/users");
  };

  return (
    <div className="sidebar">
      <div className="header" data-testid="sidebar-title">
        <img className="title-image" src={usersManager} alt="item" />
        <span className="title">Users Manager</span>
      </div>
      <div
        className={`section ${location?.pathname === "/" ? "selected" : ""}`}
        onClick={navigateToRoles}
        data-testid="roles-section"
      >
        <img className="Roles-image" src={Roles} alt="item" />
        <span className="roles-title">Roles</span>
      </div>
      <div
        className={`section ${
          location?.pathname === "/users" ? "selected" : ""
        }`}
        onClick={navigateToUsers}
        data-testid="users-section"
      >
        <img className="Users-image" src={Users} alt="item" />
        <span className="users-title">Users</span>
      </div>
    </div>
  );
};

export default Sidebar;
