import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./index.scss";
import roleService from "../../Services/Roles";
import GreenTick from "../../Assets/GreenTick.svg";

interface PopupUpdateProps {
  isOpen: boolean;
  onRequestClose: () => void;
  role: {
    Role: string;
    id: string;
    Apps: string[];
  };
}

const PopupUpdate: React.FC<PopupUpdateProps> = ({
  isOpen,
  onRequestClose,
  role,
}) => {
  const [label, setLabel] = useState<string>(role.Role);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [tags, setTags] = useState<{ value: string; label: string }[]>(
    role.Apps.map((tag) => ({ value: tag, label: tag }))
  );

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Set options excluding already selected tags
    const remainingOptions = options?.filter(
      (option) => !tags.find((tag) => tag.value === option.value)
    );

    // Check if options have changed before updating state
    if (JSON.stringify(remainingOptions) !== JSON.stringify(options)) {
      setOptions(remainingOptions);
    }
  }, [tags, options]);

  const handleRemoveTag = (removedTag: { value: string; label: string }) => {
    setTags(tags.filter((tag) => tag !== removedTag));
    setOptions([...options, removedTag]);
  };

  const handleUpdate = async () => {
    try {
      const updatedRoleData = {
        id: role.id,
        Role: label,
        Apps: tags.map((tag) => tag.label),
      };

      const response = await roleService.updateRole(updatedRoleData);

      if (response.success) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onRequestClose();
        }, 1000);
      } else {
        console.error("Error updating role:", response.message);
        setSuccess(false);
      }
    } catch (error) {
      console.error("Error updating role:", error);
      setSuccess(false);
    }
  };
  const fetchOptionsFromApi = async () => {
    try {
      // Fetch options from API using roleService or another appropriate service
      const optionsFromApi = await roleService.getApps();
      return optionsFromApi;
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };
  useEffect(() => {
    fetchOptionsFromApi().then((apiOptions) =>
      setOptions(
        apiOptions?.map((option: any) => ({
          value: option.appName,
          label: option.appName,
        }))
      )
    );
  }, []);
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 8,
      borderColor: "white",
      background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF",
      boxShadow: `0px 4px 12px 0px #00000018`,
      marginTop: "2%",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#7673C9",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? "none" : "white",
      color: state.isSelected ? "black" : "black",
      ":hover": {
        background: "#7673C9",
        color: "black",
      },
    }),
  };
  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-overlay" onClick={onRequestClose}></div>
      <div className={`modal-content ${success ? "new-role" : ""}`}>
        <div className="modal-header">
          <span>{success ? "" : "Mettre à jour le Rôle"}</span>
        </div>
        <div className="modal-body" data-testid="modal-body">
          {success ? (
            <div className="success-message" data-testid="success-message">
              <div className="SuccessContainer">
                <img src={GreenTick} alt="Green Tick" />
                <span className="success-text">
                  Rôle mis à jour avec succès
                </span>
              </div>
            </div>
          ) : (
            <>
              <input
                className="title-input"
                type="text"
                id="labelInput"
                value={label}
                placeholder="Titre"
                onChange={(e) => setLabel(e.target.value)}
                data-testid="label-input"
              />
              <Select
                styles={customStyles}
                options={options}
                placeholder="Sélectionnez une application"
                value={selectedOption}
                onChange={(selectedValue) => {
                  setSelectedOption(selectedValue);
                  if (selectedValue) {
                    setTags([...tags, selectedValue]);
                    setOptions(
                      options?.filter((option) => option !== selectedValue)
                    );
                  }
                }}
                isClearable
                isSearchable
                data-testid="select-dropdown"
              />

              <div className="tags">
                {tags.map((tag, index) => (
                  <div
                    key={tag.value}
                    className="tag"
                    data-testid={`tag-${index}`}
                  >
                    <span>{tag.label}</span>
                    <button onClick={() => handleRemoveTag(tag)}>
                      <i
                        className="bx bx-x"
                        style={{ color: "#7673C9", fontSize: 15 }}
                      ></i>
                    </button>
                  </div>
                ))}
              </div>

              <div className="buttons">
                <button onClick={onRequestClose} data-testid="Annuler-button">
                  Annuler
                </button>
                <button onClick={handleUpdate} data-testid="confirm-button">
                  Confirmer
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupUpdate;
