// PopupUsers.tsx
import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./index.scss";
import GreenTick from "../../Assets/GreenTick.svg";
import UserApiConsumer from "../../Services/users";
import roleService from "../../Services/Roles";

interface PopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onUserAdded: () => void;
}

interface StoreOption {
  value: string;
  label: string;
}

interface RoleOption {
  value: string;
  label: string;
}

const PopupUsers: React.FC<PopupProps> = ({
  isOpen,
  onRequestClose,
  onUserAdded,
}) => {
  const [fullname, setfullname] = useState<string>("");
  const [cin, setCin] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userAdded, setUserAdded] = useState(false);
  const [selectedRoleOption, setSelectedRoleOption] =
    useState<RoleOption | null>(null);
  const [selectedStoreOption, setSelectedStoreOption] =
    useState<StoreOption | null>(null);
  const [storeOptions, setStoreOptions] = useState<StoreOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const stores = await UserApiConsumer.getStores();
        const storeOptions = stores.content.map((store: any) => ({
          value: store.store.name,
          label: store.store.name,
        }));
        setStoreOptions(storeOptions);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const roles = await roleService.getAllRoles();
        const roleOptions = roles.content.map((role: any) => ({
          value: role.Role,
          label: role.Role,
        }));
        setRoleOptions(roleOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchStores();
    fetchRoles();
  }, []);

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isCinValid = (cin: string) => {
    const cinRegex = /^\d{8}$/;
    return cinRegex.test(cin);
  };

  const isPhoneValid = (phone: string) => {
    const phoneRegex = /^\d{8}$/;
    return phoneRegex.test(phone);
  };

  const isFormValid = () => {
    return (
      isEmailValid(email) &&
      isCinValid(cin) &&
      isPhoneValid(phone) &&
      selectedRoleOption &&
      selectedStoreOption
    );
  };

  const handleCancel = () => {
    onRequestClose();
  };

  const handleConfirm = async () => {
    try {
      if (!isFormValid()) {
        console.error("Form validation failed");
        return;
      }

      const userData = {
        fullname,
        cin,
        phone,
        email,
        role: selectedRoleOption?.value,
        store: selectedStoreOption?.value,
      };

      const response = await UserApiConsumer.registerUser(userData);

      if (response.success) {
        setUserAdded(true);

        setTimeout(() => {
          setUserAdded(false);
          onRequestClose();
          onUserAdded();
        }, 1000);
      } else {
        console.error("Error adding user:", response.message);
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 8,
      borderColor: "white",
      background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF",
      boxShadow: "0px 4px 12px 0px #00000018",
      width: "226px",
      height: "54px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#7673C9",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? "none" : "white",
      color: state.isSelected ? "black" : "black",
      ":hover": {
        background: "#7673C9",
        color: "black",
      },
    }),
  };

  return (
    <div className={`Usermodal ${isOpen ? "open" : ""}`}>
      <div
        className="User-modal-overlay"
        onClick={handleCancel}
        data-testid="user-modal-overlay"
      ></div>
      <div className={`User-modal-content ${userAdded ? "new-role" : ""}`}>
        <div className="User-modal-header" data-testid="user-modal-header">
          <span>{userAdded ? "" : "Nouveau User"}</span>
        </div>
        <div className="User-modal-body">
          <div
            className="User-success-message"
            data-testid="user-success-message"
          >
            {userAdded ? (
              <div className="User-SuccessContainer">
                <img src={GreenTick} alt="Green Tick" />
                <span
                  className="User-success-text"
                  data-testid="user-success-text"
                >
                  User ajouté avec succès
                </span>
              </div>
            ) : (
              <>
                <input
                  className="User-FullName-input"
                  type="text"
                  id="nameInput"
                  value={fullname}
                  placeholder="Nom & Prénom"
                  onChange={(e) => setfullname(e.target.value)}
                  data-testid="user-fullname-input"
                />
                <div className="User-Select" data-testid="user-select">
                  <Select
                    styles={customStyles}
                    options={roleOptions}
                    placeholder="Selectionner le rôle"
                    value={selectedRoleOption}
                    onChange={(selectedValue) => {
                      setSelectedRoleOption(selectedValue);
                    }}
                    isClearable
                    isSearchable
                    data-testid="role-select"
                  />
                  <Select
                    styles={customStyles}
                    options={storeOptions}
                    placeholder="Store assigné"
                    value={selectedStoreOption}
                    onChange={(selectedValue) => {
                      setSelectedStoreOption(selectedValue);
                    }}
                    isClearable
                    isSearchable
                    data-testid="store-select"
                  />
                </div>
                <input
                  className="User-CIN-input"
                  type="text"
                  id="cinInput"
                  value={cin}
                  placeholder="CIN"
                  onChange={(e) => setCin(e.target.value)}
                  data-testid="user-cin-input"
                />
                <input
                  className="User-Phone-input"
                  type="text"
                  id="phoneInput"
                  value={phone}
                  placeholder="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                  data-testid="user-phone-input"
                />
                <input
                  className="User-Email-input"
                  type="text"
                  id="emailInput"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  data-testid="user-email-input"
                />

                <div className="User-buttons">
                  <button
                    onClick={handleCancel}
                    data-testid="user-annuler-button"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={handleConfirm}
                    data-testid="user-confirmer-button"
                  >
                    Confirmer
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupUsers;
